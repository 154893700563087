import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Carousel from "../components/image-carousel"
import "../components/layout.css"
import { Button, Header } from "semantic-ui-react"
import { connect } from "react-redux"

class AboutPage extends React.Component {
  render() {
    const { lang } = this.props
    return (
      <Layout>
        <SEO title="About us" />
        <Header size="huge" style={{ textAlign: "center", marginTop: "3rem" }}>
          {lang === "english" ? "About us" : "Sobre Nosotros"}
        </Header>
        <div
          style={{
            marginTop: "3rem",
            textAlign: "center",
          }}
        >
          <div
            style={{
              fontSize: "1rem",
              // fontWeight: "bold",
              textAlign: "left",
              maxWidth: "600px",
              margin: "auto",
              marginBottom: "3rem",
              paddingLeft: "20px",
              paddingRight: "20px",
              border: "3px solid #74005f",
              borderRadius: "5px",
              boxShadow: "8px 10px #8B6084",
            }}
          >
            {lang === "english" ? (
              <>
                <p
                  style={{
                    textIndent: "40px",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  Founded in 2018, Global Connect's mission is to provide
                  Communication technology to our users, allowing them to always
                  be connected to their family, friends and work, from anywhere
                  in the world! We focus on supplying quality technology and we
                  comply with the connectivity standards that are required in
                  each country. Our multicultural work team allows us to
                  understand the needs of each region of the world, providing
                  each user with the appropriate product. Global Connect gives
                  them the devices to have the productivity, efficiency and
                  entertainment they need at all times!
                </p>
                {/* <p style={{ marginBottom: "1rem" }}>
                  We focus on supplying quality technology and we comply with
                  the connectivity standards that are required in each country.
                </p>
                <p style={{ marginBottom: "1rem" }}>
                  Our multicultural work team allows us to understand the needs
                  of each region of the world, providing each user with the
                  appropriate product.
                </p>
                <p style={{ marginBottom: "1rem" }}>
                  Global Connect gives them the devices to have the
                  productivity, efficiency and entertainment they need at all
                  times!
                </p> */}
              </>
            ) : (
              <>
                <p
                  style={{
                    textIndent: "40px",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  Fundada en el 2018 Global Connect tiene como misión proveer de
                  tecnología de Comunicación a nuestros usuarios permitiéndoles
                  estar conectados siempre a sus familiares, amigos y trabajo,
                  desde cualquier lugar del mundo! Nos enfocamos en suministrar
                  tecnología de calidad y cumplimos con los estándares de
                  conectividad que se requieren en cada país. Nuestro equipo de
                  trabajo multicultural, permite que entendamos las necesidades
                  de cada región del mundo, entregándole a cada usuario el
                  producto adecuado. Global Connect les brinda los dispositivos
                  para tener la productividad, eficiencia y entretenimiento que
                  necesitan en todo momento!
                </p>
                {/* <p style={{ marginBottom: "1rem" }}>
                  Nos enfocamos en suministrar tecnología de calidad y cumplimos
                  con los estándares de conectividad que se requieren en cada
                  país.
                </p>
                <p style={{ marginBottom: "1rem" }}>
                  Nuestro equipo de trabajo multicultural, permite que
                  entendamos las necesidades de cada región del mundo,
                  entregándole a cada usuario el producto adecuado.
                </p>
                <p style={{ marginBottom: "1rem" }}>
                  Global Connect les brinda los dispositivos para tener la
                  productividad, eficiencia y entretenimiento que necesitan en
                  todo momento!
                </p> */}
              </>
            )}
          </div>
          {/* <Button
            style={{
              marginTop: "1em",
              backgroundColor: "#74005F",
              color: "white",
            }}
            as={Link}
            to="/products"
            size="small"
          >
            {lang === "english" ? "View Products" : "Ver Productos"}
          </Button>
          <Button
            style={{
              backgroundColor: "#74005F",
              color: "white",
            }}
            as={Link}
            to="/contact"
            size="small"
          >
            {lang === "english" ? "Contact Sales" : "Contactar Ventas"}
          </Button> */}
        </div>
      </Layout>
    )
  }
}

export default connect(
  state => ({
    lang: state.lang,
  }),
  null
)(AboutPage)
